<template>
  <div>
    <Banner />
    <Section1 />
  </div>
</template>

<script>
import Banner from './Banner'
import Section1 from './Section1'

export default {
  components: {
    Banner,
    Section1
  },
  created() {
    if (this.$route.query.ENC_STR) {
      this.$bus.$emit('showRequestProfileDialog', this.$route.query.ENC_STR)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
